import { login, getInfo, accept_cgu } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'


const state = {
  token: getToken(),
  logged: false,
  user: {},
  num_results: 0
}

const mutations = {
  SET_USER: (state, user) => {
    state.user = user
  },
  SET_TOKEN: (state, token) => {
    console.log('setting token to :' +token)
    state.token = token
  },
  SET_LOGGED: (state, status) => {
    state.logged=status;
  },
  SET_NUM_RESULTS: (state, status) => {
    state.num_results=status;
  }
}

const actions = {

  accept_cgu() {
    console.log('Acception CGU');
    return new Promise((resolve, reject) => {
      accept_cgu().then(()=>{
        console.log('CGU accepted');
        resolve()
      }).catch(() =>{
        reject("Sorry, it was not possible to validate the CGU")
      })
    })
  },
  // user login
  login({ commit }, userInfo) {
    const { email, password } = userInfo
    return new Promise((resolve, reject) => {
      console.log('doing logging now');
      login({ email: email.trim(), password: password }).then(data => {
        console.log('loggin successful');
        setToken(data.token)
        commit('SET_TOKEN', data.token)
        
        resolve()
      }).catch(() => {
        reject("Sorry, there was an error with your identification, check your email and password and if the problem persist contact with your administrator.")
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    console.log('getting user info');
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        
        const data = response
       
       

        commit('SET_USER', data)
        commit('SET_LOGGED',true);
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  // user logout
  logout({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_LOGGED', false)
    removeToken()
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  setNumResults({commit}, num_results){
      commit('SET_NUM_RESULTS',num_results);
  }
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
