import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'



NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async(to,from,next)=>{
    //console.log('going to : '+to.path);
    NProgress.start()
    const hasToken = getToken();
    if(hasToken){
        //console.log('we have token');
        if(to.path === '/login'){
            // we are logged , redirect to stock
            next({ path : '/stock'})
            NProgress.done()
        } else {
            // checking if really logged :
            const logged = store.getters.logged;
            if(logged){
                if(to.path=='/' || to.path==''){
                    next({path:'/stock'})
                }
                else{
                    next();
                }
                
                NProgress.done();
            } else {
                try {
                    //console.log('Don\'t know if we are logged, getting user info');
                    await store.dispatch('user/getInfo')
                    if(to.path=='/' || to.path==''){
                        next({path:'/stock'})
                    }
                    else{
                        next();
                    }
                    NProgress.done();

                } catch (e) {
                    //console.log('Error while doing it '+e);
                    await store.dispatch('user/resetToken');
                    next(`/login?redirect=${to.path}`)
                    NProgress.done();
                }
            }

        }
    }
    else{
        if(to.path!='/login' && to.path!='/lost' && to.path!='/reset'){
            next(`/login?redirect=${to.path}`);
        }
        else{
            next();
        }
        NProgress.done();
    }
})