import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Loading from 'vue-loading-overlay';
import Vuelidate from 'vuelidate'
import VueSimpleAlert from "vue-simple-alert";
import VueLocalStorage from 'vue-localstorage'

Vue.config.productionTip = false

import 'vue-loading-overlay/dist/vue-loading.css';
import { MdApp, MdButton, MdLayout, MdIcon, MdBadge, MdDatepicker, MdField, MdCard,MdRipple, MdDialog, MdToolbar, MdMenu, MdList,MdEmptyState,MdContent, MdTable, MdCheckbox} from 'vue-material/dist/components'
import 'vue-material/dist/vue-material.min.css'
//import 'vue-material/dist/theme/default.css'


import MdVuelidated from '@undecaf/vue-material-vuelidate'
import '@undecaf/vue-material-vuelidate/dist/components.css'
Vue.use(VueLocalStorage)
Vue.use(Vuelidate)
Vue.use(MdApp)
Vue.use(MdLayout)
Vue.use(MdButton)
Vue.use(MdIcon)
Vue.use(MdBadge)
Vue.use(MdDatepicker)
Vue.use(MdField)
Vue.use(MdCard)
Vue.use(MdRipple)
Vue.use(MdDialog)
Vue.use(MdToolbar)
Vue.use(MdMenu)
Vue.use(MdList)
Vue.use(MdEmptyState)
Vue.use(MdContent)
Vue.use(MdTable)
Vue.use(MdCheckbox)

Vue.use(MdVuelidated)

Vue.use(VueSimpleAlert);

Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: 'dd/MM/yyyy',
    firstDayOfAWeek: 1,
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    shortDays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    shorterDays: ['D', 'L', 'Ma', 'Me', 'J', 'V', 'S'],
    months: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sept', 'Oct', 'Nov', 'Dec'],
    shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Juin', 'Juil', 'A', 'S', 'O', 'N', 'D'],
    cancel: 'Annuler',
    confirm: 'Ok'
  }
}
console.log(Vue.material);
Vue.use(Loading,{
    // props
    color: '#000000',
    backgroundColor:'#000000',
    opacity: 0.5,
    isFullPage: true,
    loader: 'spinner',
    canCancel: false,
    width: 128,
    height: 128,
    zIndex: 10000,

  });
import './permission' // permission control
require('./bootstrap');

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')