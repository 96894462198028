import request from '@/utils/request'

export function login(data) {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=login',
    method: 'post',
    data:data
  })
}

export function accept_cgu() {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=cgu',
    method: 'post',
    data:{accept_cgu:1}
  })
}

export function getInfo() {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=user',
    method: 'get'
  })
}

export function lostPassword(params) {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=lost',
    method: 'post',
    data:params
  })
}
export function resetPassword(params) {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=reset',
    method: 'post',
    data:params
  })
}

export function changePassword(data) {
  return request({
    url: 'https://ftvaccess.fr/api_stock.php?a=password',
    method: 'post',
    data:data
  })
}

