import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'


var Qs = require('qs');

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }
    config.paramsSerializer = params => {
      // Qs is already included in the Axios package
      return Qs.stringify(params, {
        arrayFormat: "brackets",
        encode: true
      });
    };
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  
  response => {
    if(response.data){
      return response.data;
    }
    return response;
  },

  error => {
    return Promise.reject(error)
  }
)

export default service
